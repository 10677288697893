<template>
    <div class="home">
		<headView></headView>
		<div class="head_top show">
			<img src="../assets/image/homebg.jpg" alt="">
			<div class="head_top_con">
				<div class="head_left">
					<div class="head_left_div1">AI赋能充电<br>让充电更安全</div>
					<!-- <div @click="bohao" class="head_left_div2">获取方案: 0797 8117377</div> -->
				</div>
				<div class="head_right"><img src="../assets/image/homebgs.png" alt=""></div>
			</div>
		</div>

		<div class="head_top show2">
			<img src="../assets/image/sjhome.png" alt="">
			<div class="head_top_con">
				<div class="head_left">
					<div class="head_left_div1">AI赋能充电<br>让充电更安全</div>
					<div @click="bohao" class="head_left_div2">获取建站方案</div>
				</div>
			</div>
		</div>



		<div class="box">
			<div class="box_ys">
				<div><img src="../assets/image/Shield.png" alt=""><span>安全</span></div>
				<div><img src="../assets/image/Graph.png" alt=""><span>便捷</span></div>
				<div><img src="../assets/image/Send.png" alt=""><span>智能</span></div>
				<div><img src="../assets/image/Tick.png" alt=""><span>环保</span></div>
			</div>
		</div>


		<div class="box_fwtx">
			<!-- <div class="box_fwtx" :class="{dh: dh1}"> -->
			<div class="box_fwtx2" :class="{dh: dh1}">
				<div class="box_tit box_fwtx_tit">
					<div>服务体系</div>
					<span></span>
				</div>
				<div class="box_fwtx_con">
					<div class="box_fwtx_con_left">
						<img src="../assets/image/homefw1.png" alt="">
						<div class="box_fwtx_con_nr">
							<div class="box_fwtx_con_title">智能充电设备</div>
							<div>单车充电桩｜汽车充电桩｜智能换电柜｜消防监控设备</div>
							<div>可提供硬件定制开发、整体定制化解决方案</div>
						</div>
					</div>
					<div class="box_fwtx_con_right">
						<img src="../assets/image/homefw2.png" alt="">
						<div class="box_fwtx_con_nr">
							<div class="box_fwtx_con_title">智慧数据平台</div>
							<div>大数据监测中心｜代理商管理平台｜用户服务平台｜信息交互中心</div>
							<div>可提供SAAS部署、服务器搭建、PAAS部署、软件开发等</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="dbox_cpzx">
			<div class="box_cpzx" :class="{dh: dh2}">
				<div class="box_tit box_cpzx_tit">
					<div>产品中心</div>
					<span></span>
				</div>
				<div class="box_xz">
					<div @click="onxl(1)" :class="{box_xz_js: show, box_xz_js2: !show}">AP362系列——12路</div>
					<div @click="onxl(2)" :class="{box_xz_js: !show, box_xz_js2: show}">AP350系列——10-16路</div>
				</div>
				<div v-if="show" class="box_cpzx_cont">
					<div class="box_cpzx_cont_left"><img src="../assets/image/homecp.png" alt=""></div>
					<div class="box_cpzx_cont_right">
						<div class="box_cpzx_cont_right_div1">AP362系列——12路</div>
						<div class="box_cpzx_cont_right_div2">智能管理赋能充电站运营，旧式充电站升级的性价比佳选</div>
						<div class="box_cpzx_cont_right_div3">· 集中式物联网充电站，单机支持12个充电端口<br><br>· 刷卡/扫码充电、多重费率自由设定，运维便捷<br><br>· 智能管理多个充电站，远程控制、设备状态监测<br><br>· 全程语音交互，使用更简单，防漏电设计可户外运营</div>
						<div @click="cpzx(1)" class="box_cpzx_cont_right_div4"><span style="vertical-align: middle;">查看更多</span><img style="vertical-align: middle;" src="../assets/image/jt.png" alt=""></div>
					</div>
				</div>
				<div v-if="!show" class="box_cpzx_cont">
					<div class="box_cpzx_cont_left"><img src="../assets/image/fsb.png" alt=""></div>
					<div class="box_cpzx_cont_right">
						<div class="box_cpzx_cont_right_div1">AP350系列——10-16路</div>
						<div class="box_cpzx_cont_right_div2">智能管理赋能充电站运营，旧式充电站升级的最佳选择</div>
						<div class="box_cpzx_cont_right_div3">· 集中式物联网充电站，单机 10 路/16 路充电端口<br><br>· 智能管理，安装简易，运维便捷，有漏电保护可户外运营<br><br>· 2.4 寸液晶状态显示，充电时长、充电状态可视化<br><br>· 更多充电方式，支持刷卡、扫码、输入编码充电<br><br>· 全程语音提示交互，使用更简单</div>
						<div @click="cpzx(2)" class="box_cpzx_cont_right_div4"><span style="vertical-align: middle;">查看更多</span><img style="vertical-align: middle;" src="../assets/image/jt.png" alt=""></div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="ptys_box">
			<!-- <div class="ptys_box" :class="{dh: dh3}"> -->
			<div class="ptys_box2" :class="{dh: dh3}">
				<div class="box_tit box_fwtx_tit">
					<div>平台优势</div>
					<span></span>
				</div>
				<div class="ptys">
					<img class="dimg" src="../assets/image/ptys.png" alt="">
					<img class="ximg ximg1" src="../assets/image/dulyy.png" alt="">
					<img class="ximg ximg2" src="../assets/image/shebei.png" alt="">
					<img class="ximg ximg3" src="../assets/image/xiaochengx.png" alt="">
					<img class="ximg ximg4" src="../assets/image/yunpingt.png" alt="">
					<img class="ximg ximg5" src="../assets/image/zizhangh.png" alt="">
				</div>
			</div>
		</div>

		<div class="dbox_jjfa">
			<div class="box_jjfa" :class="{dh: dh4}">
				<div class="box_tit box_tit_jjfa">
					<div>解决方案</div>
					<span></span>
				</div>
				<div class="box_jjfa_lb show">
					<img @click="left" src="../assets/image/leftjt.png" alt="">
					<div>
						<ul :style="imgleft">
							<li><img src="../assets/image/zzxq.png" alt=""></li>
							<li><img src="../assets/image/gyyq.png" alt=""></li>
							<li><img src="../assets/image/ggcs.png" alt=""></li>
							<li><img src="../assets/image/ggjt.png" alt=""></li>
						</ul>
					</div>
					<img @click="right" src="../assets/image/rightjt.png" alt="">
				</div>
				<div class="outSwiperBox show2">
					<swiper :modules="modules" :slides-per-view="1" :space-between="20" navigation
						:pagination="{ clickable: true }" :scrollbar="{ draggable: true }" @swiper="onSwiper"
						@slideChange="onSlideChange" class="inSwiperBox">
						<swiper-slide>
							<img style="width: 340px;" src="../assets/image/zzxq.png" alt="">
						</swiper-slide>
						<swiper-slide>
							<img style="width: 340px;" src="../assets/image/gyyq.png" alt="">
						</swiper-slide>
						<swiper-slide>
							<img style="width: 340px;" src="../assets/image/ggcs.png" alt="">
						</swiper-slide>
						<swiper-slide>
							<img style="width: 340px;" src="../assets/image/ggjt.png" alt="">
						</swiper-slide>
					</swiper>
				</div>

			</div>
			<!-- <div class="box_zzxq">
				<div v-if="numbt == 0">
					<div class="box_zzxq1">住宅小区</div>
					<div class="box_zzxq2">住宅小区地面停车场、地下停车场、车棚</div>
				</div>
				<div v-if="numbt == 1">
					<div class="box_zzxq1">工业园区、商业楼宇</div>
					<div class="box_zzxq2">写字楼、城市综合体、酒店等地面停车场、地下停车场</div>
				</div>
				<div v-if="numbt == 2">
					<div class="box_zzxq1">公共场所</div>
					<div class="box_zzxq2">机场、高速路服务区、大型商圈</div>
				</div>
				<div v-if="numbt == 3">
					<div class="box_zzxq1">汽车租赁、公共交通</div>
					<div class="box_zzxq2">公交集团、汽车租赁公司</div>
				</div>
				<div class="box_zzxq3">
					<img class="show" src="../assets/image/homedib.png" alt="">
					<img class="show2" src="../assets/image/sjhomedib.png" alt="">
					<div class="djcdz show3">
						<div class="div1">立即搭建自己的充电桩</div>
						<div style="padding: 0 15px;border-radius: 4px;line-height: 63px;font-size: 20px;font-weight: 600;background-color: #fff;"><img style="margin-right: 12px;width: 20px;vertical-align: middle;" src="../assets/image/dianh.png" alt=""><span>0797-8117377</span></div>
					</div>
					<div class="djcdz show4">
						<div class="div1">立即搭建自己的充电桩</div>
						
						<img @click="bohao" style="width: 24px;cursor: pointer;" src="../assets/image/jiantou.png" alt="">
					</div>
				</div>
			</div> -->



		</div>

		
		<div class="box_zzxq" style="background-color: #F1F9FF;">
			<div v-if="numbt == 0">
				<div class="box_zzxq1">住宅小区</div>
				<div class="box_zzxq2">住宅小区地面停车场、地下停车场、车棚</div>
			</div>
			<div v-if="numbt == 1">
				<div class="box_zzxq1">工业园区、商业楼宇</div>
				<div class="box_zzxq2">写字楼、城市综合体、酒店等地面停车场、地下停车场</div>
			</div>
			<div v-if="numbt == 2">
				<div class="box_zzxq1">公共场所</div>
				<div class="box_zzxq2">机场、高速路服务区、大型商圈</div>
			</div>
			<div v-if="numbt == 3">
				<div class="box_zzxq1">汽车租赁、公共交通</div>
				<div class="box_zzxq2">公交集团、汽车租赁公司</div>
			</div>
			<div class="box_zzxq3">
				<img class="show" src="../assets/image/homedib.png" alt="">
				<img class="show2" src="../assets/image/sjhomedib.png" alt="">
				<div class="djcdz show3">
					<div class="div1">立即搭建自己的充电桩</div>
					<div style="padding: 0 15px;border-radius: 4px;line-height: 63px;font-size: 20px;font-weight: 600;background-color: #fff;"><img style="margin-right: 12px;width: 20px;vertical-align: middle;" src="../assets/image/dianh.png" alt=""><span>0797-8117377</span></div>
				</div>
				<div class="djcdz show4">
					<div class="div1">立即搭建自己的充电桩</div>
					
					<img @click="bohao" style="width: 24px;cursor: pointer;" src="../assets/image/jiantou.png" alt="">
				</div>
			</div>
		</div>


		<buttonView></buttonView>
    </div>
</template>

<script setup>
import headView from '@/components/headView.vue'
import buttonView from '@/components/buttonView.vue'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router"
const router = useRouter()

const modules = [Navigation, Pagination, Scrollbar, A11y]
let dh1 = ref(false)
let dh2 = ref(false)
let dh3 = ref(false)
let dh4 = ref(false)


onMounted(async () => {
	window.scrollTo(0, 0)
	scrollTop()
});
window.addEventListener('scroll', scrollTop, true)
function scrollTop() {
    if(window.pageYOffset >= 400){
		dh1.value = true
    }
	if(window.pageYOffset >= 1000){
		dh2.value = true
	}
	if(window.pageYOffset >= 1800){
		dh3.value = true
	}
	if(window.pageYOffset >= 2400){
		dh4.value = true
	}
}

function onSlideChange(e) {
	numbt.value = e.activeIndex
}

let show = ref(true)
let numleft = ref(0)
let numbt = ref(0)
let imgleft = ref({
	left: '0px'
})
let isleft = ref(false)
let isright = ref(true)


function left() {
	if(numleft.value == 0){
		isleft.value = false
	}else{
		--numbt.value
		numleft.value = numleft.value + 1032
		imgleft.value.left = `${numleft.value}px`
	}
}
function right() {
	if(numleft.value == -3096){
		isright.value = false
	}else{
		++numbt.value
		numleft.value = numleft.value - 1032
		imgleft.value.left = `${numleft.value}px`
	}
}


function cpzx(e) {
    window.scrollTo(0, 0)
    if(e == 1){
        router.push('/product?series=AP362')
    }else if(e == 2){
        router.push('/product?series=AP350')
    }
}


function onxl(e) {
    if(e == 1){
        show.value = true
    }else if(e == 2){
        show.value = false
    }
}


function bohao() {
	window.location.href = 'tel://07978117377'
}
</script>

<style scoped lang="less">
/* 公共 */
.box_tit{
	margin-bottom: 60px;
}
.box_tit div{
	font-size: 36px;
	font-weight: 600;
	color: rgba(34, 35, 38, 0.95);
}
.box_tit span{
	display: inline-block;
	width: 30px;
	height: 3px;
	background-color: #0FC693;
}
.djcdz{
	display: flex;
	position: absolute;
	top: calc(50% - 32px);
	left: calc(50% - 180px);
}
.show{
	display: block;
}
.show2{
	display: none;
}


.show3{
	display: flex;
}
.show4{
	display: none;
}


/* .inSwiperBox {
    margin-top: 50px;
    position: initial;
} */






.home{
	min-width: 1200px;
}


/* 动画 ------------------*/

/* .box_fwtx{
	transition: all 1s;
	opacity: 0;
	margin: 0 auto;
	margin-top: 200px;
	padding: 84px 0 40px 0;
	width: 1200px;
} */
.box_fwtx{
	position: relative;
	margin: 0 auto;
	padding: 84px 0 40px 0;
	width: 1200px;
	height: 720px;
}
.box_fwtx2{
	transition: all 1s;
	position: absolute;
	top: 400px;
	opacity: 0;
	width: 100%;
}



/* .dbox_cpzx{
	padding: 60px 0 80px 0;
	background-color: #F1F9FF;
}
.box_cpzx{
	transition: all 1s;
	margin: 0 auto;
	margin-top: 200px;
	opacity: 0;
	width: 1200px;
} */
.dbox_cpzx{
	padding: 60px 0 80px 0;
	background-color: #F1F9FF;
	position: relative;
	height: 750px;
}
.box_cpzx{
	position: absolute;
	transition: all 1s;
	top: 400px;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	width: 1200px;
}






/* .ptys_box{
	transition: all 1s;
	margin: 0 auto;
	margin-top: 200px;
	opacity: 0;
	padding-top: 60px;
	width: 1200px;
}
.ptys_box2{
	transition: all 1s;
	margin: 0 auto;
	margin-top: 200px;
	opacity: 0;
	width: 1200px;
} */
.ptys_box{
	position: relative;
	margin: 0 auto;
	padding-top: 60px;
	width: 1200px;
	height: 578px;
}
.ptys_box2{
	transition: all 1s;
	position: absolute;
	top: 400px;
	opacity: 0;
	width: 100%;
}




/* .dbox_jjfa{
	padding-top: 60px;
	padding-bottom: 72px;
	background-color: #F1F9FF;
}
.box_jjfa{
	transition: all 1s;
	margin: 0 auto;
	opacity: 0;
	margin-top: 200px;
	margin-bottom: 40px;
	width: 1200px;
} */
.dbox_jjfa{
	padding-top: 60px;
	padding-bottom: 72px;
	background-color: #F1F9FF;
	position: relative;
	height: 550px;
}
.box_jjfa{
	position: absolute;
	transition: all 1s;
	top: 400px;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	width: 1200px;
}






/* -------------------------------- */

.head_top{
	position: relative;
}
.head_top img{
	width: 100%;
}
.head_top_con{
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	height: 540px;
	width: 1200px;
	text-align: left;
}
.head_left{
	padding-top: 170px;
	margin-right: 16px;
}
.head_right img{
	width: 800px;
}
.head_left_div1{
	margin-bottom: 38px;
	font-size: 64px;
	font-weight: 700;
	color: rgba(34, 35, 38, 0.95);
}
.head_left_div2{
	display: inline-block;
	padding: 16px 40px;
	border-radius: 2px;
	cursor: pointer;
	font-size: 24px;
	color: #FFFFFF;
	background: #0FC693;
}








.box{
	position: relative;
	width: 1200px;
	margin: 0 auto;
}
.box_ys{
	position: absolute;
	top: -45px;
	padding: 20px 0;
	display: flex;
	justify-content: space-around;
	background: linear-gradient(180deg, #F2F5FA 0%, #FFFFFF 100%);
	box-shadow: 0px 4px 10px 0px rgba(30, 31, 67, 0.08);
	width: 1200px;
}
.box_ys div img{
	width: 48px;
	margin-right: 12px;
	vertical-align: middle;
}
.box_ys div span{
	vertical-align: middle;
}
.box_fwtx_con{
	display: flex;
	justify-content: space-between;
}
.box_fwtx_con_left,
.box_fwtx_con_right{
	overflow: hidden;
	border: 1px solid #E9E9E9;
	border-radius: 20px;
}
.box_fwtx_con_left img,
.box_fwtx_con_right img{
	width: 580px;
}
.box_fwtx_con_left div,
.box_fwtx_con_right div{
	margin: 0 auto;
	width: 470px;
}
.box_fwtx_con_nr{
	padding: 50px 0 36px 0;
}
.box_fwtx_con_nr div{
	font-size: 16px;
	color: #888F98;
}
.box_fwtx_con_nr .box_fwtx_con_title{
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: 600;
	color: #3D3D3D;
}









.box_xz{
	margin-bottom: 20px;
}
.box_xz .box_xz_js{
	background: #0FC693;
	color: #FFFFFF;
}
.box_xz .box_xz_js2{
	background-color: #fff;
	color: rgba(34, 35, 38, 0.95);
}
.box_xz div{
	transition: all 0.2s;
	display: inline-block;
	padding: 10px 38px;
	font-size: 18px;
	color: rgba(34, 35, 38, 0.95);
	cursor: pointer;
}
/* .box_xz div:hover{
	background: #0FC693;
	color: #FFFFFF;
} */
.box_cpzx_cont{
	padding: 40px;
	display: flex;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
	background-color: #fff;
}
.box_cpzx_cont>div>img{
	width: 556px;
}
.box_cpzx_cont_left{
	margin-right: 40px;
}
.box_cpzx_cont_right{
	margin-top: 48px;
	text-align: left;
}
.box_cpzx_cont_right_div1{
	margin-bottom: 4px;
	font-size: 24px;
	color: rgba(34, 35, 38, 0.95);
}
.box_cpzx_cont_right_div2{
	margin-bottom: 64px;
	font-size: 16px;
	color: #888F98;
}
.box_cpzx_cont_right_div3{
	margin-bottom: 40px;
	font-size: 14px;
	color: rgba(34, 35, 38, 0.95);
}
.box_cpzx_cont_right_div4{
	vertical-align: middle;
	display: inline-block;
	padding: 10px 38px;
	border: 1px solid #E9E9E9;
	color: #0FC693;
	cursor: pointer;
	font-size: 18px;
}
.box_cpzx_cont_right_div4 img{
	width: 18px;
	vertical-align: middle;
}










.ptys{
	margin-top: 128px;
	position: relative;
	font-size: 0;
}
.dimg{
	width: 693px;
}
.ximg{
	position: absolute;
	width: 324px;
}
.ximg1{
	top: 60px;
	right: 90px;
}

.ximg2{
	top: 100px;
	left: 45px;
}
.ximg3{
	top: -12px;
	left: 130px;
}
.ximg4{
	top: 220px;
	left: 0px;
}
.ximg5{
	top: 220px;
	right: 0px;
}
















.box_tit_jjfa{
	margin-bottom: 44px;
}
.box_jjfa_lb div{
	position: relative;
	margin: 0 36px;
	overflow: hidden;
	width: 1032px;
}
.box_jjfa_lb>div>ul{
	transition: all 1s;
	padding-left: 0;
	display: flex;
	position: absolute;
}
.box_jjfa_lb div ul li{
	list-style: none;
}
.box_jjfa_lb{
	display: flex;
	height: 420px;
}
.box_jjfa_lb>img{
	margin-top: 195px;
	cursor: pointer;
	width: 48px;
	height: 48px;
}
.box_jjfa_lb>div>ul>li>img{
	width: 1032px;
}


.box_zzxq1{
	margin-bottom: 4px;
	font-size: 28px;
	font-weight: 600;
	color: #3D3D3D;
}
.box_zzxq2{
	margin-bottom: 60px;
	font-size: 18px;
	font-weight: 600;
	color: #3D3D3D;
}
.box_zzxq3{
	width: 100%;
	position: relative;
	margin-bottom: 60px;
}
.box_zzxq3 img{
	width: 100%;
}

/* .box_zzxq3 .show2{
	display: none;
} */
.div1{
	margin-right: 90px;
	font-size: 36px;
	line-height: 63px;
	color: rgba(34, 35, 38, 0.95);
}
.div2{
	padding: 16px 67px;
	border-radius: 2px;
	/* cursor: pointer; */
	font-size: 24px;
	color: #FFFFFF;
	background: #0FC693;
}


.dh{
	opacity: 1;
	margin-top: 0px;
	top: 80px;
}





@media (max-width: 520px) {


	
	/* .dbox_cpzx{
		padding: 40px 0 20px 0;
	} */
	.dbox_cpzx{
		padding: 40px 0 20px 0;
		height: 790px;
	}


	
	/* .ptys_box{
		padding-top: 40px;
		margin-top: 0;
		opacity: 1;
		width: 350px;
	} */
	.ptys_box{
		padding-top: 40px;
		margin-top: 0;
		opacity: 1;
		width: 350px;
		height: 388px;
	}


	
	/* .dbox_jjfa{
		padding-top: 40px;
		padding-bottom: 12px;
	} */
	.dbox_jjfa{
		padding-top: 40px;
		padding-bottom: 12px;
		height: 240px;
	}

	/* --------------- */

	.box_tit{
		margin-bottom: 24px;
	}
	.box_tit div{
		margin-bottom: -10px;
		font-size: 24px;
		font-weight: 600;
		color: rgba(34, 35, 38, 0.95);
	}


	.djcdz{
		display: flex;
		position: absolute;
		top: calc(50% - 8px);
		left: calc(50% - 28px);
	}

	/* .box_zzxq3>div{
		display: flex;
		position: absolute;
		top: calc(50% - 32px);
		left: calc(50% - 180px);
	} */
	.home{
		min-width: 350px;
	}

	.show{
		display: none;
	}
	.show3{
		display: none;
	}
	/* .box_zzxq3 .show{
		display: none;
	} */
	.show2{
		display: block;
	}
	.show4{
		display: flex;
	}
	/* .box_zzxq3 .show2{
		display: block;
	} */
	.head_top_con{
		display: block;
		position: absolute;
		top: 300px;
		height: 190px;
		width: 100%;
		text-align: center;
	}
	.head_left{
		padding-top: 0px;
		margin-right: 0px;
	}
	.head_left_div1{
		font-size: 36px;
	}
	.head_left_div2{
		font-size: 18px;
	}
	.box{
		position: relative;
		width: 100%;
	}
	.box_ys{
		width: 100%;
		top: -10px;
	}
	.box_ys div img{
		width: 20px;
	}
	.box_ys div span{
		font-size: 14px;
	}


	.box_fwtx{
		padding: 80px 0 16px 0;
		width: 350px;
		opacity: 1;
		margin-top: 0;
	}
	.box_fwtx_con{
		display: block;
	}
	.box_fwtx_con_left{
		margin-bottom: 12px;
	}
	.box_fwtx_con_left div,
	.box_fwtx_con_right div{
		margin: 0 auto;
		width: 100%;
	}
	.box_fwtx_con_left img,
	.box_fwtx_con_right img{
		width: 343px;
	}
	.box_fwtx_con_nr{
		box-sizing: border-box;
		padding: 20px 16px 23px 16px;
	}
	.box_fwtx_con_nr div{
		font-size: 13px;
	}
	.box_fwtx_con_nr .box_fwtx_con_title{
		margin-bottom: 16px;
		font-size: 18px;
	}





	.box_cpzx{
		margin: 0 auto;
		margin-top: 0;
		opacity: 1;
		width: 350px;
	}
	.box_cpzx_cont{
		padding: 16px;
		display: block;
	}
	.box_cpzx_cont>div>img{
		width: 311px;
	}
	.box_xz div{
		padding: 8px 16px;
		font-size: 12px;
	}
	.box_cpzx_cont_right_div1{
		font-size: 20px;
	}
	.box_cpzx_cont_right_div2,
	.box_cpzx_cont_right_div3{
		margin-bottom: 28px;
		font-size: 14px;
	}
	







	.dimg{
		width: 343px;
	}
	.ximg{
		width: 130px;
	}
	.ximg1{
		top: -60px;
		right: 0px;
	}
	.ximg2{
		top: -110px;
		left: 30px;
	}
	.ximg3{
		top: -10px;
		left: 0px;
	}
	.ximg4{
		top: -60px;
		left: 50px;
	}
	.ximg5{
		top: -5px;
		right: -10px;
	}






	.box_jjfa{
		margin-top: 0;
		opacity: 1;
		margin-bottom: 10px;
		width: 350px;
	}



	.box_zzxq1{
		font-size: 16px;
	}
	.box_zzxq2{
		font-size: 14px;
	}

	.div1{
		margin-right: 15px;
		font-size: 16px;
		line-height: 24px;
	}

	.box_cpzx_cont_right_div4{
		margin: 0 85px;
		font-size: 14px;
	}
	.box_cpzx_cont_right_div4 img{
		width: 12px;
	}

}
</style>