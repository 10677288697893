<template>
    <div class="hello">
        <div class="box">
            <div class="left">
                <div class="box_left">
                    <div class="title" @click="ddbtz(1)">关于我们</div>

                    <div @click="ddbtz(1)">企业介绍</div>
                    <div @click="ddbtz(1)">使命愿景</div>
                    <div @click="ddbtz(1)">平台介绍</div>
                    <div @click="ddbtz(1)">平台特色</div>
                </div>
                <div class="box_left">
                    <div class="title" @click="ddbtz(2)">产品中心</div>

                    <div @click="ddbtz2(1)">AP362系列</div>
                    <div @click="ddbtz2(2)">AP350系列</div>
                </div>
                <div class="box_left">
                    <div class="title" @click="ddbtz(3)">解决方案</div>

                    <div @click="ddbtz3(1)">住宅小区</div>
                    <div @click="ddbtz3(2)">工业园区/写字楼</div>
                    <div @click="ddbtz3(3)">公共场所</div>
                    <div @click="ddbtz3(4)">骑车租赁/公共交通</div>
                </div>
                <div class="box_left">
                    <div class="title" @click="ddbtz(4)">合作赋能</div>

                    <div @click="ddbtz(4)">新闻资讯</div>
                </div>
            </div>
            <div>
                <div class="ewmtit">充电拉小程序</div>
                <img src="../assets/image/erweim.png" alt="">
            </div>
        </div>
        <div class="button">©2023-2024 江西帝讯新能源有限公司 <span style="margin: 0 12px;">|</span> <span class="jlt"></span><a class="color: #E5E5E5;" target="_Blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36079002000474">赣公网安备36079002000474号</a> <span style="margin: 0 12px;">|</span> <a class="color: #E5E5E5;" target="_Blank" href="https://beian.miit.gov.cn">赣ICP备2023005993号-2</a></div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router"
const route = useRoute()
const router = useRouter()
function ddbtz(e) {
    if(e == 1){
        router.push('/about')
        window.scrollTo(0, 0)
    }else if(e == 2){
        router.push('/product')
    }else if(e == 3){
        router.push('/solution')
    }else if(e == 4){
        router.push('/cooperate')
        window.scrollTo(0, 0)
    }
}
function ddbtz2(e) {
    window.scrollTo(0, 0)
    if(e == 1){
        router.push('/product?series=AP362')
    }else if(e == 2){
        router.push('/product?series=AP350')
    }
}

function ddbtz3(e) {
    if(e == 1){
        if(route.meta.name == 'solution'){
            window.scrollTo(0, 0)
        }else{
            router.push('/solution?programme=1')
        }
    }else if(e == 2){
        if(route.meta.name == 'solution'){
            if(isMobile()){
                window.scrollTo(0, 400)
            }else{
                window.scrollTo(0, 840)
            }
        }else{
            router.push('/solution?programme=2')
        }
    }else if(e == 3){
        if(route.meta.name == 'solution'){
            if(isMobile()){
                window.scrollTo(0, 740)
            }else{
                window.scrollTo(0, 1450)
            }
        }else{
            router.push('/solution?programme=3')
        }
    }else if(e == 4){
        if(route.meta.name == 'solution'){
            if(isMobile()){
                window.scrollTo(0, 1070)
            }else{
                window.scrollTo(0, 2000)
            }
        }else{
            router.push('/solution?programme=4')
        }
    }
}



function isMobile() {
    const userAgentInfo = window.navigator.userAgent;
    return /(Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini)/.test(userAgentInfo);
}
</script>

<style scoped lang="less">
.hello{
    padding-top: 40px;
    background: #232323;
}
.box{
    margin: 0 auto;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    width: 1200px;
}
.ewmtit{
    font-size: 19px;
    color: #F6F6F6;
}
.box div img{
    margin-top: 24px;
    width: 140px;
}
.left{
    display: flex;
    justify-content: space-between;
    width: 60%;
}
.box_left div{
    cursor: pointer;
    margin-bottom: 20px;
    text-align: left;
    font-size: 14px;
    color: #E5E5E5;
}
.title{
    cursor: pointer;
}
.box_left .title{
    margin-bottom: 40px;
    font-size: 19px;
    color: #F6F6F6;
}
.button{
    padding: 28px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    color: #E5E5E5;
}
.button a{
    color: #E5E5E5 !important;
}
.jlt {
	display: inline-block;
	margin-right: 4px;
    width: 16px;
    height: 16px;
    background-image: url('../assets/image/jingh.png');
    background-size: contain;
	vertical-align: middle;
}

@media (max-width: 520px) {
    .box{
        margin-bottom: 28px;
        display: block;
        width: 350px;
    }
    .left{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }
    .box_left{
        margin-bottom: 28px;
        width: 50%;
    }
    .box_left .title{
        margin-bottom: 25px;
        font-size: 19px;
    }
}
</style>
