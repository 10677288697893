import { createRouter, createWebHashHistory } from 'vue-router'
// , createWebHistory
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      name: 'home',
    },
    component: HomeView
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      name: 'product',
    },
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/solution',
    name: 'solution',
    meta: {
      name: 'solution',
    },
    component: () => import('../views/SolutionView.vue')
  },
  {
    path: '/cooperate',
    name: 'cooperate',
    meta: {
      name: 'cooperate',
    },
    component: () => import('../views/CooperateView.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      name: 'about',
    },
    component: () => import('../views/AboutView.vue')
  }
]

  // history: createWebHistory(process.env.BASE_URL),
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

router.afterEach((to, from) => {
  if('/product' == from.fullPath || '/product?series=AP362' == from.fullPath || '/product?series=AP350' == from.fullPath){
    location.reload()
  }
});

export default router
