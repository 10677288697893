<template>
    <div :class="{hello2: true, hello: $route.meta.name != 'home' || top}" class="show" :style="obj">
        <div class="box">
            <div @click="jump('home')" class="logo">
                <img src="../assets/image/logo.png" alt="">
            </div>
            <div style="display: flex;">
                <div class="right">
                    <div class="div" @click="jump('home')" :class="{ggtz: $route.meta.name == 'home'}">首页</div>
                    <div class="div" @click="jump('product')" :class="{ggtz: $route.meta.name == 'product'}">产品中心</div>
                    <div class="div" @click="jump('solution')" :class="{ggtz: $route.meta.name == 'solution'}">解决方案</div>
                    <div class="div" @click="jump('cooperate')" :class="{ggtz: $route.meta.name == 'cooperate'}">合作赋能</div>
                    <div class="div" @click="jump('about')" :class="{ggtz: $route.meta.name == 'about'}">关于我们</div>
                </div>
                <div style="margin-left: 40px;padding: 0 15px;border-radius: 4px;font-size: 20px;line-height: 46px; font-weight: 600;background-color: #fff;"><img style="margin-right: 12px;width: 20px;vertical-align: middle;" src="../assets/image/dianh.png" alt=""><span>0797-8117377</span></div>
            </div>
        </div>
    </div>
    

    <div>
        <div :class="{hello3: true, hello: !cdis || top}" class="show2">
            <div class="box">
                <div @click="jump('home')" class="logo">
                    <img src="../assets/image/logo.png" alt="">
                </div>
                <div>
                    <img v-if="cdis" @click="oncd" style="width: 24px;height: 24px;" src="../assets/image/zhankai.png" alt="">
                    <img v-if="!cdis" @click="ongb" style="width: 24px;height: 24px;" src="../assets/image/guanbi.png" alt="">
                </div>

            </div>
            <div class="right2" :class="{right3: !cdis}">
                <div @click="jump('home')" :class="{ggtz: $route.meta.name == 'home'}">首页</div>
                <div @click="jump('product')" :class="{ggtz: $route.meta.name == 'product'}">产品中心</div>
                <div @click="jump('solution')" :class="{ggtz: $route.meta.name == 'solution'}">解决方案</div>
                <div @click="jump('cooperate')" :class="{ggtz: $route.meta.name == 'cooperate'}">合作赋能</div>
                <div @click="jump('about')" :class="{ggtz: $route.meta.name == 'about'}">关于我们</div>
            </div>


        </div>

		<div @click="onmc" :class="{mc: !cdis}">
            
		</div>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router"
import { ref, onMounted } from 'vue';
// , useRoute
// const route = useRoute()
const router = useRouter()
let top = ref(true)
let obj = ref({
    left: '0px'
})
let cdis = ref(true)

window.addEventListener('scroll', scrollTop, true)
onMounted(() => {
	// window.scrollTo(0, 0)
	scrollTop()
});




function scrollTop() {
    if(window.pageYOffset >= 100){
        top.value = true
    }else{
        top.value = false
    }
    obj.value.left = `-${window.pageXOffset}px`
}


function jump(e) {
    if(e == 'home'){
        router.push('/')
    }else if(e == 'product'){
        router.push('/product')
    }else if(e == 'solution'){
        router.push('/solution')
    }else if(e == 'cooperate'){
        router.push('/cooperate')
    }else if(e == 'about'){
        router.push('/about')
    }
}

function oncd(){
    cdis.value = false
}
function ongb() {
    cdis.value = true
}
function onmc() {
    cdis.value = true
}
</script>

<style scoped lang="less">
.show{
    display: block;
}
.show2{
    display: none;
}
.hello2{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 86px;
    z-index: 100;
    min-width: 1200px;
}
.hello{
    background-color: #fff;
}
.box{
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    width: 1200px;
    z-index: 100;
}
.logo{
    cursor: pointer;
    font-size: 0px;
}
.logo>img{
    width: 160px;
}
.right{
    margin-top: 12px;
    display: flex;
    height: 34px;
    /* line-height: 50%; */
}
.right div{
    margin-left: 60px;
}
.right .div{
    transition: all 0.3s;
    margin-left: 60px;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    color: rgba(34, 35, 38, 0.95);
}
.right .ggtz{
    font-weight: 600;
    border-bottom: 2px solid #0FC693;
    color: #0FC693;
}
.right .div:hover{
    color: #0FC693;
    border-bottom: 2px solid #0FC693;
}


.mc{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
}


@media (max-width: 520px) {
    
    .show{
        display: none;
    }
    .show2{
        display: block;
    }
    .hello3{
        
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        height: 45px;
        width: 100vw;
    }
    .box{
        margin: 8px auto;
        width: 330px;
    }
    .logo>img{
        width: 100px;
    }
    .right2{
        transition: all 0.2s;
        padding-bottom: 16px;
        padding-top: 28px;
        margin-top: 0;
        display: block;
        position: fixed;
        top: -320px;
        width: 100%;
        z-index: -1;
        background-color: #fff;
    }
    .right3{
        top: 45px;
    }
    .right2 div{
        transition: all 0.3s;
        padding: 12px 10px;
        margin-bottom: 8px;
        margin-left: 0;
        cursor: pointer;
    }
    .right2 div:hover{
        background-color: rgba(15, 198, 147, 0.1);
        color: #0FC693;
    }
    .right2 .ggtz{
        border-bottom: none;
        background-color: rgba(15, 198, 147, 0.1);
        font-weight: 600;
        color: #0FC693;
    }


}
</style>
